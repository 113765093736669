import React from "react"
import styles from "./MatchHistory.module.css"
import Icon from '../Utilities/Icon'

import statIsSet from "../../js/statIsSet"

class Match extends React.Component {

  state = {
    moreStatsActive: false,
  };

  toggleMoreStats = () => {
    this.setState(state => ({ moreStatsActive: !state.moreStatsActive }));
  };

  render() {

    const d = this.props.data

    const active = this.state.moreStatsActive

    const nonOptaAssists = (d.deflectedAssist > 0 || d.reboundGkAssist > 0  || d.reboundPostAssist > 0 || d.ownGoalAssist > 0 || d.penWonAssist > 0) ? true : false

    const moreStats = (d.opponentLeaguePosition && d.opponentLeaguePosition !== '-') || (d.shots && d.shots !== '-') || (d.deflectedAssist > 0 || d.reboundGkAssist > 0  || d.reboundPostAssist > 0 || d.ownGoalAssist > 0 || d.penWonAssist > 0) ? true : false

    return (

      <div className="mb-4 md:mb-5 text-sm text-gray-300 md:flex md:flex-wrap relative">

        <div className={`${styles.metaWrap} flex text-xs md:flex md:flex-wrap`}>
          {/* <span className="flex items-center mr-1 text-gray-500 py-1 px-1 md:flex-auto md:m-0 md:border-r-0 md:hidden lg:block lg:absolute lg:top-0 lg:right-full lg:px-2 bg-noir-lighten10 opacity-50 hover:opacity-100">#{this.props.index}</span> */}
          <span className="flex items-center mr-1 text-gray-500 py-1 px-2 border border-gray-700 border-b-0 md:flex-auto md:m-0 md:border-r-0">{d.date ? d.date.replace(/-/g, '/') : ''}/{d.year}</span>
          <span className="flex items-center mr-1 text-gray-500 py-1 px-2 border border-gray-700 border-b-0 md:flex-auto md:m-0 md:border-r-0">{d.season ? d.season.replace(/-/g, '/').replace(/20/g,'').replace('/21','20/21').replace('19/','19/20') : ''}</span>
          <span className="flex items-center mr-1 text-gray-500 py-1 px-2 border border-gray-700 border-b-0 md:flex-auto md:m-0 md:border-r-0 md:border-b">{d.competition === "Champions League" ? 'Champs League' : d.competition === "UEFA European Championships" ? 'UEFA Euros' : d.competition === "UEFA Nations League" ? 'Nations League' : d.competition}</span>
          {d.round ? (
            <span className="flex items-center mr-1 text-gray-500 py-1 px-2 border border-gray-700 border-b-0 md:flex-auto md:m-0 md:border-r-0 md:border-b">{d.round === "3rd Place Play-off" ? '3rd P/O' : d.round === "F" ? 'Final' : d.round}</span>
          ) : ''}
        </div>

        <div className="flex bg-noir-lighten10 border border-gray-700 md:flex-1">

          <span className={`flex flex-col p-2 justify-center items-center bg-noir-lighten5 text-gray-500 text-sm border-r border-gray-700`}>
            <span>{d.homeAway}</span>
            <span className={`block border border-t-2 w-full mt-2 ${Number(d.scoreTeam) > Number(d.scoreOpponent) ? 'border-green-600' : Number(d.scoreOpponent) > Number(d.scoreTeam) ? 'border-red-700' : 'border-highlight-darken10'}`}></span>
          </span>

          <div className="flex flex-wrap text-sm w-2/5 leading-tight">

            <div className="flex w-full leading-none border-b border-gray-700">
              <span className="flex items-center w-4/5 py-1 px-2 border-r border-gray-700 overflow-hidden">
                <span className="block w-full whitespace-no-wrap truncate">{d.homeAway === "A" ? d.opponent : d.team}</span>
              </span>
              <span className={`flex items-center justify-center bg-noir-lighten5 w-1/5 p-1 font-semibold border-r ${d.scoreTeam > d.scoreOpponent ? 'border-gray-700' : d.scoreTeam < d.scoreOpponent ? 'border-gray-700' : 'border-gray-700'}`}>
                {d.homeAway === "A" ? d.scoreOpponent : d.scoreTeam}
              </span>
            </div>

            <div className="flex w-full leading-none">
              <span className="flex items-center w-4/5 py-1 px-2 border-r border-gray-700 overflow-hidden">
                <span className="block w-full whitespace-no-wrap truncate">{d.homeAway === "A" ? d.team : d.opponent}</span>
              </span>
              <span className={`flex items-center justify-center bg-noir-lighten5 w-1/5 p-1 font-semibold border-r ${d.scoreTeam > d.scoreOpponent ? 'border-gray-700' : d.scoreTeam < d.scoreOpponent ? 'border-gray-700' : 'border-gray-700'}`}>
                {d.homeAway === "A" ? d.scoreTeam : d.scoreOpponent}
              </span>
            </div>

          </div>

          <div className="flex flex-1 leading-tight">
            <div className="flex flex-1 flex-col justify-center text-center p-2 border-r border-noir">
              <span className={`font-bold text-lg ${d.goals > 0 ? 'text-highlight' : 'text-gray-500'}`}>{d.goals}</span>
              <span className="text-xs py-1 text-gray-300">Goals</span>
            </div>

            <div className="flex flex-1 flex-col justify-center text-center p-2 border-r border-noir">
              <span className={`font-bold text-lg ${d.assists > 0 ? 'text-highlight' : 'text-gray-500'}`}>{d.assists}</span>
              <span className="text-xs py-1 text-gray-300">Assists</span>
            </div>

            <div className="w-2/5 flex flex-col text-center text-xs leading-tight">
              <span className="flex flex-1 justify-center items-center px-2 py-1">{d.minsPlayed} mins <span className="sr-only">played</span></span>
              {d.rating > 0 ? (
                <span className={`${styles.rating} flex flex-1 items-center justify-center px-2 py-1 border-t border-gray-700`}>
                  {d.motm > 0 ? <Icon iconName="icon-star" class="text-highlight mr-1" /> : ''}
                  {d.rating} <small className="text-gray-500">/10</small>
                  {d.notes && (d.notes.includes('sofascore') || d.notes.includes('fotmob')) ? <span className="ml-1">*</span> : ''}
                </span>
              ) : ''}
            </div>
          </div>

        </div>

        {d.ftScore ? (
          <div className={`${styles.extraTimeBar} bg-noir-lighten10 border-b border-gray-700 w-full md:order-1`}>
            <span>FT Score: <strong>{d.ftScore}</strong></span>
            {d.penScore ? (
              <span>Pens Score: <strong>{d.penScore} {Number(d.penScore.split('-')[1]) > Number(d.penScore.split('-')[0]) ? '(L)' : '(W)'}</strong></span>
            ) : ''}
            {d.shootout ? (
              <span>Shootout: <strong>{d.shootout === 'x' ? (
                <>
                  Missed <Icon iconName="icon-times" class="text-red-500 text-xs" />
                </>
              ) : (
                <>
                  Scored <Icon iconName="icon-check" class="text-green-500 text-xs" />
                </>
              )}</strong></span>
            ) : ''}
          </div>
        ) : ''}

        <div className={`${styles.goalDetails} flex flex-row-reverse bg-noir-lighten5  border-gray-700 border-t-0 text-xs text-highlight-darken5 md:relative`}>

            <button
              disabled={!moreStats ? true : false}
              onClick={this.toggleMoreStats}
              className={`font-semibold px-2 py-1 border-l focus:outline-none ${this.props.player === "Messi" ? 'border-messi-darken10 text-messi' : 'border-ronaldo-darken10 text-ronaldo hover:text-ronaldo-lighten10 focus:text-ronaldo-lighten10 focus:border-ronaldo-lighten10 active:text-ronaldo-lighten10 active:border-ronaldo-lighten10'} ${active ? 'border-b-0' : 'border-b'} md:border-r md:border-t md:absolute md:bottom-0 md:right-0 ${!moreStats ? 'opacity-50' : ''} ${this.props.player === "Messi" && moreStats ? 'hover:text-messi-lighten10 focus:text-messi-lighten10 focus:border-messi-lighten10 active:text-messi-lighten10 active:border-messi-lighten10' : this.props.player === "Ronaldo" && moreStats ? 'hover:text-ronaldo-lighten10 focus:text-ronaldo-lighten10 focus:border-ronaldo-lighten10 active:text-ronaldo-lighten10 active:border-ronaldo-lighten10' : ''}`}>
                {active ? (
                  <span>less <Icon iconName="icon-angle-up" /></span>
                ) : (
                  <span>more <Icon iconName="icon-angle-down" /></span>
                )}
            </button>

          {d.goals > 0 ? (
            <>
              <div className="px-2 md:w-3/4 md:flex-1">
                {d.pens > 0 ? (
                  <span>{d.pens} pen <span className="sr-only">goal</span></span>
                ) : ''}
                {d.freeKicks > 0 ? (
                  <span>{d.freeKicks} free kick <span className="sr-only">goal</span></span>
                ) : ''}
                {d.insideBox > 0 ? (
                  <span>{d.insideBox} inside box <span className="sr-only">goal</span></span>
                ) : ''}
                {d.outsideBox > 0 ? (
                  <span>{d.outsideBox} outside box <span className="sr-only">goal</span></span>
                ) : ''}
              </div>
              <div className="px-2 border-r border-gray-700 md:border-0 md:w-3/4 md:flex-1">
                {d.left > 0 ? (
                  <span>{d.left} left foot <span className="sr-only">goal</span></span>
                ) : ''}
                {d.right > 0 ? (
                  <span>{d.right} right foot <span className="sr-only">goal</span></span>
                ) : ''}
                {d.head > 0 ? (
                  <span>{d.head} header <span className="sr-only">goal</span></span>
                ) : ''}
                {d.other > 0 ? (
                  <span>{d.other} other <span className="sr-only">goal</span></span>
                ) : ''}
              </div>
            </>
          ) : ''}
          {d.pensMissed > 0 ? (
            <div className="px-2 border-r border-gray-700 text-red-500 md:border-0 md:w-2/3 md:flex-1">
              <span>{d.pensMissed} pen missed</span>
            </div>
          ) : ''}
        </div>


        <div className={`${active ? 'block mb-8 md:mb-4 md:mt-1 border w-full md:order-1' : 'hidden mb-0'} ${this.props.player === "Messi" ? 'border-messi' : 'border-ronaldo'} md:border-gray-700 md:border-t-0`}>

          {d.shots && d.shots !== '-' ? (
          <div className={`flex flex-wrap border-l border-t border-gray-700 bg-noir-lighten10`}>
            <div className={`${styles.perfStat} ${statIsSet(d.shots) || 'text-gray-600'}`}>
              <strong className={`${styles.perfStatVal}`}>{statIsSet(d.shots) || <small>N/A</small>}</strong>
              <span className={`${styles.perfStatKey}`}>Shots</span>
            </div>
            <div className={`${styles.perfStat} ${statIsSet(d.shotsOnTarget) || 'text-gray-600'}`}>
              <strong className={`${styles.perfStatVal}`}>{statIsSet(d.shotsOnTarget) || <small>N/A</small>}</strong>
              <span className={`${styles.perfStatKey}`}>Shots on<br /> Target</span>
            </div>
            <div className={`${styles.perfStat} ${statIsSet(d.freeKickAttempts) || 'text-gray-600'}`}>
              <strong className={`${styles.perfStatVal}`}>{statIsSet(d.freeKickAttempts) || <small>N/A</small>}</strong>
              <span className={`${styles.perfStatKey}`}>Free Kick<br /> Attempts</span>
            </div>
            <div className={`${styles.perfStat} ${statIsSet(d.successfulDribbles) || 'text-gray-600'}`}>
              <strong className={`${styles.perfStatVal}`}>{statIsSet(d.successfulDribbles) || <small>N/A</small>}</strong>
              <span className={`${styles.perfStatKey}`}>Successful<br /> Dribbles</span>
            </div>
            <div className={`${styles.perfStat} ${statIsSet(d.keyPasses) || 'text-gray-600'}`}>
              <strong className={`${styles.perfStatVal}`}>{statIsSet(d.keyPasses) || <small>N/A</small>}</strong>
              <span className={`${styles.perfStatKey}`}>Key<br /> Passes</span>
            </div>
            <div className={`${styles.perfStat} ${statIsSet(d.bigChancesCreated) || 'text-gray-600'}`}>
              <strong className={`${styles.perfStatVal}`}>{statIsSet(d.bigChancesCreated) || <small>N/A</small>}</strong>
              <span className={`${styles.perfStatKey}`}>Big Chances<br /> Created</span>
            </div>
            <div className={`${styles.perfStat} ${statIsSet(d.throughballs) || 'text-gray-600'}`}>
              <strong className={`${styles.perfStatVal}`}>{statIsSet(d.throughballs) || <small>N/A</small>}</strong>
              <span className={`${styles.perfStatKey}`}>Accurate<br /> Throughballs</span>
            </div>
            <div className={`${styles.perfStat} ${statIsSet(d.aerialDuels) || 'text-gray-600'}`}>
              <strong className={`${styles.perfStatVal}`}>{statIsSet(d.aerialDuels) || <small>N/A</small>}</strong>
              <span className={`${styles.perfStatKey}`}>Aerial<br /> Duels Won</span>
            </div>
            <div className={`${styles.perfStat} ${statIsSet(d.xg) || 'text-gray-600'}`}>
              <strong className={`${styles.perfStatVal}`}>{statIsSet(d.xg) || <small>N/A</small>}</strong>
              <span className={`${styles.perfStatKey}`}>xG</span>
            </div>
            <div className={`${styles.perfStat} ${statIsSet(d.xa) || 'text-gray-600'}`}>
              <strong className={`${styles.perfStatVal}`}>{statIsSet(d.xa) || <small>N/A</small>}</strong>
              <span className={`${styles.perfStatKey}`}>xA</span>
            </div>
            <div className={`${styles.perfStat} ${statIsSet(d.motm) || 'text-gray-600'}`}>
              <strong className={`${styles.perfStatVal}`}>{d.motm > 0 ? 'Yes' : 'No'}</strong>
              <span className={`${styles.perfStatKey}`}>MOTM</span>
            </div>
            <div className={`${styles.perfStat} ${statIsSet(d.rating) || 'text-gray-600'}`}>
              <strong className={`${styles.perfStatVal}`}>{statIsSet(d.rating) || <small>N/A</small>}</strong>
              <span className={`${styles.perfStatKey}`}>Match<br /> Rating</span>
            </div>
          </div>
          ) : ''}


          {nonOptaAssists || d.opponentLeaguePosition ? (
            <div className={`${styles.allAssists} p-1 text-xs bg-noir-lighten5`}>

              {d.opponentLeaguePosition && (d.team === "Argentina" || d.team === "Portugal") ? (
                <span className="text-gray-500">Opponent FIFA Ranking:</span>
              ) : d.opponentLeaguePosition && (d.competition === "Champions League") ? (
                <span className="text-gray-500">UEFA Club Coefficient Ranking:</span>
              ) : d.opponentLeaguePosition ? (
                <span className="text-gray-500">Opponent league finish:</span>
              ) : ''}
              {d.opponentLeaguePosition ? <span className='mr-2'>#{d.opponentLeaguePosition}</span> : ''}

              {nonOptaAssists ? <span className="text-highlight">Non-Opta Assists:</span> : '' }
              {/* {d.allAssists > 0 ? '' : <span>0 Assists</span> }
              {d.allAssists > 0 && d.assists < 1 ? <span>0 Opta</span> : '' }
              {d.assists > 0 ? <span>{d.assists+' Opta'}</span> : '' } */}
              {d.deflectedAssist > 0 ? <span>{d.deflectedAssist+' deflected'}</span> : '' }
              {d.reboundGkAssist > 0 ? <span>{d.reboundGkAssist+' GK rebound'}</span> : '' }
              {d.reboundPostAssist > 0 ? <span>{d.reboundPostAssist+' post rebound'}</span> : '' }
              {d.ownGoalAssist > 0 ? <span>{d.ownGoalAssist+' own goal assist'}</span> : '' }
              {d.penWonAssist > 0 ? <span>{d.penWonAssist+' pen won'}</span> : '' }

            </div>
          ) : ''}

        </div>

        {/* {d.notes && d.notes.includes('sofascore') ? <span className="block w-full md:order-1 px-2 lg:px-0 mt-1 text-xs text-gray-600">* Rating from SofaScore</span> : ''}

        {d.notes && d.notes.includes('fotmob') ? <span className="block w-full md:order-1 px-2 lg:px-0 mt-1 text-xs text-gray-600">* Rating from FotMob</span> : ''} */}

      </div>

    )
  }
}

export default Match